import { useState, useEffect, useRef } from "react"

export default function useComponentVisible(
  initialIsVisible: boolean,
  closeCallback?: () => void
) {
  const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible)
  const [isLocked, setIsLocked] = useState(false)
  const ref = useRef<HTMLDivElement>(null)

  const close = () => {
    setIsComponentVisible(false)
    if (closeCallback) {
      closeCallback()
    }
  }

  const handleHideDropdown = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      close()
    }
  }

  const handleClickOutside = (event: Event) => {
    if (
      ref.current &&
      !ref.current.contains(event.target as Node) &&
      !isLocked
    ) {
      close()
    }
  }

  useEffect(() => {
    document.addEventListener("keydown", handleHideDropdown, true)
    document.addEventListener("click", handleClickOutside, true)
    return () => {
      document.removeEventListener("keydown", handleHideDropdown, true)
      document.removeEventListener("click", handleClickOutside, true)
    }
  })

  return { ref, isComponentVisible, setIsComponentVisible, setIsLocked }
}
