import { IDefinition } from "@tripetto/map"

export const formDefinition: IDefinition = {
  name: "dd-service",
  language: "nl",
  prologue: {
    title:
      "Bedankt dat je interesse hebt in onze diensten. Laten we beginnen met enkele vragen.",
    image:
      "https://images.ctfassets.net/b0e3znxl2ne5/2SHfdsmIpX67h0GW2dYfdg/49ce3ae357e086712f47b058f7368877/logo_main.png",
  },
  epilogue: {
    title: "Bedankt voor de informatie. We nemen binnenkort contact met je op!",
  },
  clusters: [
    {
      id: "ebef07c003034dace7a6e904e9444302dbcdaa92435018f6268e00292048f884",
      name: "👋 Hi there! Click the block below to start",
      nodes: [
        {
          id:
            "afbd06d03849de1f5669993e44995ca346ce8c31432197a13848fd145c108b81",
          name: "Wat is je voornaam?",
          nameVisible: true,
          slots: [
            {
              id:
                "623c80efebdc11227c473fde51c623b7f0974238d4467c4160c92c29465de71a",
              type: "text",
              kind: "static",
              reference: "value",
              label: "Text",
              required: true,
            },
          ],
          block: {
            type: "tripetto-block-text",
            version: "4.2.0",
          },
        },
        {
          id:
            "1b4b1f802f053c29d6d428f838c6e0a558c59e94192e02d6846df1a15ce1ae1a",
          name: "Wat is je achternaam?",
          nameVisible: true,
          slots: [
            {
              id:
                "63e6a011c5fc077bf5059558a1c8ac41dac0dd5979c5d38249c438c7392d0da9",
              type: "text",
              kind: "static",
              reference: "value",
              label: "Text",
              required: true,
            },
          ],
          block: {
            type: "tripetto-block-text",
            version: "4.2.0",
          },
        },
        {
          id:
            "01acbf6fc50d113697345d2ed9ebbfad6da88d17da7432fafefaae7525a4560d",
          name:
            "Naar welke dienst ben je op zoek, @623c80efebdc11227c473fde51c623b7f0974238d4467c4160c92c29465de71a?",
          nameVisible: true,
          slots: [
            {
              id:
                "11db9c3434c071ef547f3d8b71e78cb80ee7670e1b0655d222e1e790323bcf59",
              type: "string",
              kind: "static",
              reference: "choice",
              label: "Image",
              required: true,
            },
          ],
          block: {
            type: "tripetto-block-picture-choice",
            version: "2.2.0",
            choices: [
              {
                id:
                  "24f0800cd76da863708f05430224d384f8118639061370f6f94a6ef48134e8db",
                name: "Service & Onderhoud",
                nameVisible: true,
                image:
                  "https://images.ctfassets.net/b0e3znxl2ne5/4qf128dJeP9rYoTNCkf36B/c9d4a8ca345ca4a10e34d3a8b08e1075/Website_voorpagina_foto_2_verspringen_.jpg",
              },
              {
                id:
                  "1d2ad24cd4988b0f5f2b97710ebeeb7ae08514926b98ee2f0de2c112992b2471",
                name: "Nieuwe installatie",
                nameVisible: true,
                image:
                  "https://images.ctfassets.net/b0e3znxl2ne5/1JgMhdZjDw0Pt6Oc9NMJAI/99c30e3c1347863a42d39ccb2c4e7e13/1.jpg",
              },
              {
                id:
                  "e3d8a74eddd7af2b43d008c1a69d5d667851adeba78b23e1e109da20649bc396",
                name: "Zonnepanelen",
                nameVisible: true,
                image:
                  "https://images.ctfassets.net/b0e3znxl2ne5/i7ZHuwVLixk8LJ1SGDofd/0dd44b8ee3c72920d91f1978b76e66b6/solar.jpg",
              },
            ],
            required: true,
          },
        },
      ],
      branches: [
        {
          id:
            "cfecb3dda4dcb10c05d433885bc8b272d14ef77e267a430b53c803a723b9d3d9",
          name: "Service & Onderhoud",
          clusters: [
            {
              id:
                "fbfe31f26a28f6930c9054644f10fd2823ee7e2f6c26c3feba7819f46a5b8df4",
              name: "Onderhoud",
              nodes: [
                {
                  id:
                    "ba6d2391cf8f9d24b4d7c24c7a1021459fe91678911b2328360f5bddc7adff81",
                  name: "Welke type installatie is het?",
                  nameVisible: true,
                  slots: [
                    {
                      id:
                        "9ff5d9286af943475c42f2f88baec08a21d8820ef905d2ec1b2d9dfcdda60004",
                      type: "string",
                      kind: "static",
                      reference: "button",
                      label: "Selected button",
                    },
                  ],
                  block: {
                    type: "tripetto-block-radiobuttons",
                    version: "4.2.0",
                    buttons: [
                      {
                        id:
                          "7dddb755e2dfe0a1d3336b33a4dad447134d438fdc4f2f457c38607d29c24953",
                        name: "Gas",
                      },
                      {
                        id:
                          "06868671bc7c81cd959d8d9ea54f5e3bc3181d6f582ba0d89fd0d1d026d7885e",
                        name: "Stookolie",
                      },
                      {
                        id:
                          "259705b9e1baa67273bcff6227130c9b3a2682a7df662ac8799a60830c3c5c71",
                        name: "Andere",
                      },
                    ],
                  },
                },
              ],
              branches: [
                {
                  id:
                    "0b579d13b261aebc9a232e0d2a37656e48fd779b606395638e9512f4ca274682",
                  name: "Ander type installatie",
                  clusters: [
                    {
                      id:
                        "30e3f150853c2f15a02017df5b287540d10b2d3d6fa81ab8583a1cb4f8f9c66e",
                      nodes: [
                        {
                          id:
                            "6a43885ea7e01e27ce14ca810b95e782f0575808683490c4382a9dcd6f851126",
                          name:
                            "Geef hier een beschrijving van het type installatie",
                          nameVisible: true,
                          slots: [
                            {
                              id:
                                "52d4b855068852815ee09d064442a7af93ff2aa0d4116b206317f861d22a92d6",
                              type: "text",
                              kind: "static",
                              reference: "value",
                              label: "Text",
                            },
                          ],
                          block: {
                            type: "tripetto-block-text",
                            version: "4.2.0",
                          },
                        },
                      ],
                    },
                  ],
                  conditions: [
                    {
                      id:
                        "5ed7542d1536f5b8c5cf7a6d3a75429a59df62bc1cb7de5904f54ea470bdeef2",
                      block: {
                        button:
                          "259705b9e1baa67273bcff6227130c9b3a2682a7df662ac8799a60830c3c5c71",
                        type: "tripetto-block-radiobuttons",
                        version: "4.2.0",
                        node:
                          "ba6d2391cf8f9d24b4d7c24c7a1021459fe91678911b2328360f5bddc7adff81",
                        slot:
                          "9ff5d9286af943475c42f2f88baec08a21d8820ef905d2ec1b2d9dfcdda60004",
                      },
                    },
                  ],
                },
              ],
            },
            {
              id:
                "8454332cdd5e9f85a29e996f5fe5c3627aabd75e590b726b945c77299fb62427",
              nodes: [
                {
                  id:
                    "faa100c270c8bbb004e5b7a889762a168b7b180a14bdaf1badbeb2f8b14fd881",
                  name: "Wat is het merk van de installatie?",
                  nameVisible: true,
                  slots: [
                    {
                      id:
                        "450534080fca06e5d547ebe37095b7adf2e181a3444964cf2e445ba3fad3f3a2",
                      type: "text",
                      kind: "static",
                      reference: "value",
                      label: "Text",
                    },
                  ],
                  block: {
                    type: "tripetto-block-text",
                    version: "4.2.0",
                  },
                },
                {
                  id:
                    "1cdfdd0558fd4b34824648b09d73f3a79d1509fc26f73711266d3a29764a88ef",
                  name: "Wat is het model van de installatie?",
                  nameVisible: true,
                  slots: [
                    {
                      id:
                        "a65b3071b6550fda67d43b788e9259cab8c7bba06f1b175356de2395edb036ee",
                      type: "text",
                      kind: "static",
                      reference: "value",
                      label: "Text",
                    },
                  ],
                  block: {
                    type: "tripetto-block-text",
                    version: "4.2.0",
                  },
                },
              ],
            },
          ],
          conditions: [
            {
              id:
                "0ddea24036a1b515c3c4d2d8bf000c0d8e15bfb32d5cf3ca233fa04087e5a1a9",
              block: {
                choice:
                  "24f0800cd76da863708f05430224d384f8118639061370f6f94a6ef48134e8db",
                type: "tripetto-block-picture-choice",
                version: "2.2.0",
                node:
                  "01acbf6fc50d113697345d2ed9ebbfad6da88d17da7432fafefaae7525a4560d",
                slot:
                  "11db9c3434c071ef547f3d8b71e78cb80ee7670e1b0655d222e1e790323bcf59",
              },
            },
          ],
        },
        {
          id:
            "fe94fefbe91fdf23bfc924497970bb4dd7f0ebf8574f75092c422f2054e1a363",
          name: "Nieuwe installatie",
          clusters: [
            {
              id:
                "92bf9d686cc9ca9447cdd9be77c561b01fe6bf22ed96e42c71709720a8a7d226",
              nodes: [
                {
                  id:
                    "97d18a1c6aec7a527ef8ca82df8cf0a442275dd4e13f54a0851e011f08207791",
                  name: "Geef hier wat extra uitleg indien je dit wenst.",
                  nameVisible: true,
                  slots: [
                    {
                      id:
                        "10da4018355bee211fc8b8fc5c399902b3a608cc286ec9dd566851ead7e6bd83",
                      type: "text",
                      kind: "static",
                      reference: "value",
                      label: "Multi-line text",
                    },
                  ],
                  block: {
                    type: "tripetto-block-textarea",
                    version: "4.1.0",
                  },
                },
              ],
            },
          ],
          conditions: [
            {
              id:
                "fc1626918637144493ca4a1eb11f0e3db07998471d49d462917a146bf17ac244",
              block: {
                choice:
                  "1d2ad24cd4988b0f5f2b97710ebeeb7ae08514926b98ee2f0de2c112992b2471",
                type: "tripetto-block-picture-choice",
                version: "2.2.0",
                node:
                  "01acbf6fc50d113697345d2ed9ebbfad6da88d17da7432fafefaae7525a4560d",
                slot:
                  "11db9c3434c071ef547f3d8b71e78cb80ee7670e1b0655d222e1e790323bcf59",
              },
            },
            {
              id:
                "c7b2ce83e84c28a7c64e0f0619ef1eafe248302eba381489be7f1b7be84f3ae0",
              block: {
                choice:
                  "e3d8a74eddd7af2b43d008c1a69d5d667851adeba78b23e1e109da20649bc396",
                type: "tripetto-block-picture-choice",
                version: "2.2.0",
                node:
                  "01acbf6fc50d113697345d2ed9ebbfad6da88d17da7432fafefaae7525a4560d",
                slot:
                  "11db9c3434c071ef547f3d8b71e78cb80ee7670e1b0655d222e1e790323bcf59",
              },
            },
          ],
        },
      ],
    },
    {
      id: "3ebd9ec66d01c8c2ae159c40d85b5b0cf7cb5f24584edf28c796c5e693ec671c",
      nodes: [
        {
          id:
            "8d901f98dd0051c92829ddf0a9ba29a8f5c69513aa2dad8a8535e6dfe2890b98",
          name: "Hoe wil je dat we contact met je opnemen?",
          nameVisible: true,
          slots: [
            {
              id:
                "279046179f1a2e5b5cd9ae895cdc1a94256748a267f597a0e5319fb1e1e945be",
              type: "string",
              kind: "static",
              reference: "button",
              label: "Selected button",
            },
          ],
          block: {
            type: "tripetto-block-radiobuttons",
            version: "4.2.0",
            buttons: [
              {
                id:
                  "4a3a617cf4b1270ae23c798d7418e2e32a2f86d7c3a2c9ad5dfc583a9354fe2a",
                name: "Telefonisch",
              },
              {
                id:
                  "b0eae67e5dedae19e047458ac235a3b7b8eac40dc073482ea34b5043351d7f17",
                name: "Via email",
              },
            ],
          },
        },
      ],
      branches: [
        {
          id:
            "b10caf0d1791bdaa44ccc75944d5f48a13a9bb81560162160ff1db70854deabf",
          name: "Telefonisch",
          clusters: [
            {
              id:
                "ffe56047f27e12af39f6a9282e985116d364e2f52265722291d00f53cc805ff2",
              nodes: [
                {
                  id:
                    "675f3be4c392ec6b41e408648ecdf6afd43f9d356187c4e24b47d07dcf8a608f",
                  name: "Wat is je telefoonnummer?",
                  nameVisible: true,
                  placeholder: "+32 412 345 678",
                  slots: [
                    {
                      id:
                        "0e95fb544df066c5e6601f6d85a0af5678b0fe26cfa055bf879414089427aa20",
                      type: "string",
                      kind: "static",
                      reference: "phone-number",
                      label: "Phone number",
                      required: true,
                    },
                  ],
                  block: {
                    type: "tripetto-block-phone-number",
                    version: "2.0.4",
                  },
                },
              ],
            },
          ],
          conditions: [
            {
              id:
                "d01e00cfc0386f4561540ef2254cc8a62f95e52869989de2bf2dd1f5c20c54aa",
              block: {
                button:
                  "4a3a617cf4b1270ae23c798d7418e2e32a2f86d7c3a2c9ad5dfc583a9354fe2a",
                type: "tripetto-block-radiobuttons",
                version: "4.2.0",
                node:
                  "8d901f98dd0051c92829ddf0a9ba29a8f5c69513aa2dad8a8535e6dfe2890b98",
                slot:
                  "279046179f1a2e5b5cd9ae895cdc1a94256748a267f597a0e5319fb1e1e945be",
              },
            },
          ],
        },
        {
          id:
            "b92b916a99abc2bde3b75db6147b39a4ace4e53944c5918ae57e82abc04f2d92",
          name: "Via email",
          clusters: [
            {
              id:
                "03ea8cbf0f108416d6b9ccfe00401a655f6caeaf7529b3c1dad6ef42db97f487",
              nodes: [
                {
                  id:
                    "5e1ea39dc485fd9c27192ea5d982e5d022c9c0f500144673588e00bf58b1a69f",
                  name: "Wat is je e-mailadres?",
                  nameVisible: true,
                  slots: [
                    {
                      id:
                        "febd3ee686a3a515e058c9eec06338600e029175117778a26364133e192aa024",
                      type: "string",
                      kind: "static",
                      reference: "email",
                      label: "Email address",
                      required: true,
                    },
                  ],
                  block: {
                    type: "tripetto-block-email",
                    version: "4.0.5",
                  },
                },
              ],
            },
          ],
          conditions: [
            {
              id:
                "5931250d8a9c857fcf37e179eb7c9d9025f874d62f6a10372b76fe2b6240efe9",
              block: {
                button:
                  "b0eae67e5dedae19e047458ac235a3b7b8eac40dc073482ea34b5043351d7f17",
                type: "tripetto-block-radiobuttons",
                version: "4.2.0",
                node:
                  "8d901f98dd0051c92829ddf0a9ba29a8f5c69513aa2dad8a8535e6dfe2890b98",
                slot:
                  "279046179f1a2e5b5cd9ae895cdc1a94256748a267f597a0e5319fb1e1e945be",
              },
            },
          ],
        },
      ],
    },
  ],
  builder: { name: "tripetto", version: "3.10.3" },
}
