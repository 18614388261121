import classNames from "classnames"
import { graphql, Link, useStaticQuery } from "gatsby"
import React, { useEffect, useState } from "react"
import useComponentVisible from "../../hooks/use-component-visible/use-component-visible"
import logo from "../../images/logo.png"

import Services from "tripetto-services"
import { formDefinition } from "./form-definition"

const { snapshot, attachments, onSubmit, onPause } = Services.init({
  token:
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoielkyT0xINzl6ZGxhMnhTZ3VYeExTUHp6SGdCS0ZYSno3b0Q2TkhCbjgrST0iLCJkZWZpbml0aW9uIjoiMng3Ly9HUjlRQUpleldacUtNaEhZZFhWNmxYdnhHUldnWDZTamc1Z2JJdz0iLCJ0eXBlIjoiY29sbGVjdCJ9.-x6TOYMbaYRzZRM7RQ1d4nVXuYnP7QPMMY-8sernLlE",
})

interface Page {
  slug: string
  title: string
}

const Navbar = () => {
  const pages: Page[] = useStaticQuery(
    graphql`
      query PageListQuery {
        allContentfulPage(sort: { fields: ordering, order: ASC }) {
          nodes {
            slug
            title
          }
        }
      }
    `
  ).allContentfulPage.nodes

  const [typeFormInitialized, setTypeFormInitialized] = useState(false)
  const [hostElement, setHostElement] = useState<HTMLDivElement | undefined>(
    undefined
  )
  const modal = useComponentVisible(false)
  const [hamburgerOpen, setHamburgerOpen] = useState(false)
  useEffect(() => {
    if (!typeFormInitialized && !!hostElement) {
      import("tripetto-runner-autoscroll").then(runner => {
        runner.run({
          element: hostElement,
          definition: formDefinition,
          styles: {
            direction: "vertical",
            verticalAlignment: "middle",
            hidePast: false,
            hideUpcoming: false,
            showNavigation: "auto",
            showProgressbar: true,
            showEnumerators: false,
            autoFocus: false,
            showSeparateSubmit: true,
            showPreviousButton: true,
            showScrollbar: false,
            noBranding: false,
            contract: {
              name: "tripetto-runner-autoscroll",
              version: "4.0.1",
            },
            font: { size: 16, family: "Source Sans Pro" },
          },
          attachments: attachments as any,
          onSubmit,
          persistent: false,
        })

        setTypeFormInitialized(true)
      })
    }
  })

  return (
    <>
      <nav className="navbar">
        <div className="container">
          <div className="navbar-brand">
            <Link to="/" className="navbar-item">
              <img src={logo} alt="DD Service" />
            </Link>
            <span
              className={classNames("navbar-burger burger", {
                "is-active": hamburgerOpen,
              })}
              data-target="navbarMenuHeroA"
              onClick={() => setHamburgerOpen(!hamburgerOpen)}
            >
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </span>
          </div>
          <div
            id="navbarMenuHeroA"
            className={classNames("navbar-menu", {
              "is-active": hamburgerOpen,
            })}
          >
            <div className="navbar-end">
              <Link to="/" className="navbar-item" activeClassName="is-active">
                Home
              </Link>
              {pages.map(page => (
                <Link
                  key={page.slug}
                  to={`/${page.slug}`}
                  className="navbar-item"
                  activeClassName="is-active"
                >
                  {page.title}
                </Link>
              ))}
              {/* <Link
                to="/over-mij"
                className="navbar-item"
                activeClassName="is-active"
              >
                Over mij
              </Link> */}
              <span className="navbar-item">
                <button
                  className="button is-primary is-inverted"
                  data-mode="popup"
                  onClick={() => modal.setIsComponentVisible(true)}
                >
                  <span>Offerte vragen?</span>
                </button>
              </span>
            </div>
          </div>
        </div>
      </nav>

      <div
        className={classNames("modal", {
          "is-active": modal.isComponentVisible,
          "is-clipped": modal.isComponentVisible,
        })}
      >
        <div className="modal-background"></div>
        <div className="modal-content is-huge">
          <div className="container modal-full-height" ref={modal.ref}>
            <div
              className="modal-full-height__content has-background-white p-6"
              ref={element => {
                if (!element || typeFormInitialized) {
                  return
                }

                setHostElement(element)
              }}
            />
          </div>
        </div>
        <button
          className="modal-close is-large"
          aria-label="close"
          onClick={() => modal.setIsComponentVisible(false)}
        ></button>
      </div>
    </>
  )
}

export default Navbar
